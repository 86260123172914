import axios from '../../axios'
import NProgress from "nprogress";

export default {
    state: {
        cartDetails: [],
        updateCartItemResponse: [],
        deleteCartItemResponse: [],
        clearCartResponse: [],
        applyVoucherResponse: [],
        removeVoucherResponse: [],
        updateCartInfoResponse: [],
        cartItemCount: 0
    },
    getters: {
        cartDetails: (state) => state.cartDetails,
        updateCartItemResponse: (state) => state.updateCartItemResponse,
        deleteCartItemResponse: (state) => state.deleteCartItemResponse,
        clearCartResponse: (state) => state.clearCartResponse,
        applyVoucherResponse: (state) => state.applyVoucherResponse,
        removeVoucherResponse: (state) => state.removeVoucherResponse,
        updateCartInfoResponse: (state) => state.updateCartInfoResponse,
        cartItemCount: (state) => state.cartItemCount
    },
    mutations: {
        SET_CART_DETAILS(state, data) {
            state.cartDetails = data;
        },
        SET_UPDATE_CART_ITEM_RESPONSE(state, data) {
            state.updateCartItemResponse = data;
        },
        SET_DELETE_CART_ITEM_RESPONSE(state, data) {
            state.deleteCartItemResponse = data;
        },
        SET_CLEAR_CART_RESPONSE(state, data) {
            state.clearCartResponse = data;
        },
        SET_APPLY_VOUCHER_RESPONSE(state, data) {
            state.applyVoucherResponse = data;
        },
        SET_REMOVE_VOUCHER_RESPONSE(state, data) {
            state.removeVoucherResponse = data;
        },
        SET_UPDATE_CART_INFO_RESPONSE(state, data) {
            state.updateCartInfoResponse = data;
        },
        SET_CART_ITEM_COUNT(state, data) {
            state.cartItemCount = data;
        }
    },
    actions: {

        async addCartItem({commit}, data) {
            NProgress.start();
            await axios.post(`carts`, data).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('getCartDetails');
                        commit("SET_UPDATE_CART_ITEM_RESPONSE", res.data);
                    } else {
                        commit("SET_UPDATE_CART_ITEM_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_UPDATE_CART_ITEM_RESPONSE", error.response.data);
                }
            );
        },

        async getCartDetails({commit}) {
            await axios.get(`carts`).then(
                (res) => {
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('updateCartItemCount', res.data.response.cart);
                        commit("SET_CART_DETAILS", res.data);
                    } else {
                        commit("SET_CART_DETAILS", []);
                    }
                },
                (error) => {
                    commit("SET_CART_DETAILS", error.response.data);
                }
            );
        },

        async updateCartItem({commit}, data) {
            NProgress.start();
            await axios.put(`carts/items`, {
                quantity: data.quantity,
                cart_id: data.cart_id,
                item_id: data.item_id
            }).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('getCartDetails');
                        commit("SET_UPDATE_CART_ITEM_RESPONSE", res.data);
                    } else {
                        commit("SET_UPDATE_CART_ITEM_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_UPDATE_CART_ITEM_RESPONSE", error.response.data);
                }
            );
        },

        async deleteCartItem({ commit }, id) {
            NProgress.start();
            await axios.delete(`carts/items/${id}`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('getCartDetails');
                        commit("SET_DELETE_CART_ITEM_RESPONSE", res.data);
                    } else {
                        commit("SET_DELETE_CART_ITEM_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_DELETE_CART_ITEM_RESPONSE", error.response.data);
                }
            );
        },

        async clearCart({ commit }) {
            NProgress.start();
            await axios.delete(`carts/clear`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('getCartDetails');
                        commit("SET_CLEAR_CART_RESPONSE", res.data);
                    } else {
                        commit("SET_CLEAR_CART_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_CLEAR_CART_RESPONSE", error.response.data);
                }
            );
        },

        async applyVoucher({ commit }, data) {
            NProgress.start();
            await axios.post("carts/voucher", {
                coupon_code : data.coupon_code
            }).then((response) => {
                NProgress.done();
                if(response.data.code == 401) {
                    this.dispatch('logout');
                }else if (response.data.status) {
                    this.dispatch('getCartDetails');
                    commit("SET_APPLY_VOUCHER_RESPONSE", response.data);
                } else {
                    commit("SET_APPLY_VOUCHER_RESPONSE", response.data);
                }
            },
                (error) => {
                    NProgress.done();
                    commit("SET_APPLY_VOUCHER_RESPONSE", error.response.data);
                }
            );
        },

        async removeVoucher({ commit }) {
            NProgress.start();
            await axios.delete(`carts/voucher`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('getCartDetails');
                        commit("SET_REMOVE_VOUCHER_RESPONSE", res.data);
                    } else {
                        commit("SET_REMOVE_VOUCHER_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_REMOVE_VOUCHER_RESPONSE", error.response.data);
                }
            );
        },

        async updateCartInfo({commit}, data) {
            NProgress.start();
            await axios.put(`carts`, {
                cart_id: data.cart_id,
                comment: data?.comment,
                address_id: data.address_id
            }).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('getCartDetails');
                        commit("SET_UPDATE_CART_INFO_RESPONSE", res.data);
                    } else {
                        commit("SET_UPDATE_CART_INFO_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_UPDATE_CART_INFO_RESPONSE", error.response.data);
                }
            );
        },

        async updateCartItemCount({commit}, cart) {
            if(cart != null && cart?.items?.length > 0) {
                commit("SET_CART_ITEM_COUNT", cart?.items.length);
            } else {
                commit("SET_CART_ITEM_COUNT", 0);
            }
        },

    }
}